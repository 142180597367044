<template>
  <div class="app-container">
    <el-row>
      <el-col :lg="{span: 10, offset: 7}">
        <el-card v-loading="isLoading">
          <template #header>
            <div>
              <h2 class="heading page-heading">
                {{ $t('page_add_quarter_report.title') }}
              </h2>
            </div>
          </template>
          <template #default>
            <el-form
                v-model="form"
                :rules="formRules"
                :model="form"
                ref="form"
            >
              <el-row :gutter="20">
                <el-col :span="24">
                  <el-form-item :label="$t('page_add_quarter_report.fields.unit_title')" prop="unitId">
                    <el-select v-model="form.unitId">
                      <el-option
                          v-for="item in unitList"
                          :key="item.unitId"
                          :label="item.unitTitle"
                          :value="item.unitId">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item :label="$t('page_add_quarter_report.fields.date_range')" prop="dateRange">
                    <v-date-picker v-model="form.dateRange"
                                   class="w-100"
                                   input-type="daterange"
                                   :clearable="false"
                                   :picker-options="{ disabledDate }"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item :label="$t('page_add_quarter_report.fields.post')" prop="postId">
                    <el-select v-model="form.postId">
                      <el-option
                          v-for="item in unitPosts"
                          :key="item.postId"
                          :label="item.title"
                          :value="item.postId">
                        <span style="float: left; margin-right: 4px">{{ item.title }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ formatDate(item.createdAt) }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item :label="$t('page_add_quarter_report.fields.email_content')" prop="description">
                    <el-input
                        type="textarea"
                        v-model="form.description"
                        :maxlength="300"
                        show-word-limit
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-button type="primary" @click="addQuarterReport">
                    {{ $t('page_add_quarter_report.btn_submit') }}
                  </el-button>
                  <el-button type="primary" @click="openPreviewModal">
                    {{ $t('page_add_quarter_report.btn_preview') }}
                  </el-button>
                  <el-button @click="forwardToReportList">
                    {{ $t('page_add_quarter_report.btn_cancel') }}
                  </el-button>
                </el-col>
              </el-row>
            </el-form>
          </template>
        </el-card>
      </el-col>
    </el-row>
    <v-dialog ref="previewModal"
              :title="$t('page_add_quarter_report.preview_modal.title')"
    >
      <template #body>
        <el-form :model="previewForm"
                 :rules="previewFormRules"
                 ref="previewForm"
                 v-loading="isSendingPreview"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item :label="$t('page_add_quarter_report.preview_modal.field_name')" prop="name">
                <el-input v-model="previewForm.name" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('page_add_quarter_report.preview_modal.field_email')" prop="email">
                <el-input v-model="previewForm.email" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template #actions="{close, confirm}">
        <v-button @click="close">
          {{ $t('page_add_quarter_report.preview_modal.btn_close') }}
        </v-button>
        <v-button @click="sendPreviewQuarterReport(confirm)"
                  type="primary"
        >
          {{ $t('page_add_quarter_report.preview_modal.btn_submit') }}
        </v-button>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import {
  fetchUnitPosts
} from '../../../../client/unit-page/services/unit-post-service'
import {
  addQuarterReport,
  sendPreviewQuarterReport,
  fetchUnitList
} from '../../services/report-service'

import {formatDate} from '../../../../shared/utils/formatDate'

import VDatePicker from '../../../../components/ui/v-date-picker/VDatePicker'

import {ADMIN_QUARTER_REPORT_ROUTE_NAME} from '../../../../shared/constants/app-routes-names'
import VDialog from '../../../../components/ui/v-dialog/VDialog'
import VButton from '../../../../components/ui/v-button/VButton'

const QUARTERS = ['Q1', 'Q2', 'Q3', 'Q4']

export default {
  name: 'AddReport',
  components: {
    VButton,
    VDialog,
    VDatePicker,
  },
  data() {
    return {
      isLoading: false,
      isSendingPreview: false,
      form: {
        unitId: '',
        dateRange: [],
        postId: '',
        description: ''
      },
      previewForm: {
        name: '',
        email: ''
      },
      previewFormRules: {
        name: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
        email: [
          {required: true, message: this.$t('validators.field_required'),},
          {type: 'email', message: this.$t('validators.incorrect_email')}
        ],
      },
      formRules: {
        unitId: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
        dateRange: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
        postId: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
        description: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
      },
      unitList: [],
      unitPosts: [],
      quarters: QUARTERS
    }
  },
  watch: {
    ['form.unitId'](newValue) {
      this.fetchPosts(newValue)
      this.form.postId = ''
    }
  },
  created() {
    this.fetchUnitList()
  },
  methods: {
    formatDate,
    fetchUnitList() {
      fetchUnitList().then((res) => {
            this.unitList = res
          })
          .catch((e) => {
            this.$message({
              type: 'error',
              message: `${this.$t(e)}`
            })
          })
    },
    fetchPosts(unitId) {
      this.isLoading = true
      fetchUnitPosts(unitId)
          .then((res) => {
            this.unitPosts = res
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    forwardToReportList() {
       this.$router.push({ name: ADMIN_QUARTER_REPORT_ROUTE_NAME})
    },
    addQuarterReport() {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        this.isLoading = true
        addQuarterReport(this.form)
          .then(() => {
            this.$message({
              message: `${this.$t('page_add_quarter_report.send_success')}`,
              type: 'success'
            })
            this.forwardToReportList()
          })
          .catch(({response}) => {
            this.$message({
              message: `${this.$t('alerts.common_error', { status: response.status})}`,
              type: 'error'
            })
          })
        .finally(() => {
          this.isLoading = false
        })
      })
    },
    openPreviewModal() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return
        await this.$refs.previewModal.openDialog()
      })
    },
    sendPreviewQuarterReport(closeFunc) {
      this.$refs.previewForm.validate((valid) => {
        if (!valid) return
        this.isSendingPreview = true
        sendPreviewQuarterReport({
          ...this.form,
          ...this.previewForm
        })
          .then(() => {
            this.$message({
              message: `${this.$t('page_add_quarter_report.send_success')}`,
              type: 'success'
            })
            closeFunc()
          })
          .catch(({response}) => {
            this.$message({
              message: `${this.$t('alerts.common_error', { status: response.status})}`,
              type: 'error'
            })
          })
        .finally(() => {
          this.isSendingPreview = false
        })
      })
    },
    disabledDate(d) {
      const date = new Date()
      return d.getFullYear() > date.getFullYear()
    },
  },
}
</script>
